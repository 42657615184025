/**
 * Constant representing the base URL for the API.
 *
 * @type {string}
 */
export const API_BASE_URL = SERVICE_URL;

/**
 * Constant representing the full URL for the records API call.
 *
 * @type {string}
 */
export const API_RECORDS = `${API_BASE_URL}/api/records`;

/**
 * Constant representing the full URL for the companies API call.
 *
 * @type {string}
 */
export const API_COMPANIES = `${API_BASE_URL}/api/companies`;

/**
 * Constant representing the full URL for the time series API call.
 *
 * @type {string}
 */
export const API_SERIES = `${API_BASE_URL}/api/series`;

/**
 * Constant representing the default year for comparisons.
 *
 * @type {int}
 */
export const DEFAULT_COMPARISON_YEAR = 2022;

/**
 * Constant representing the default job key for comparisons.
 *
 * @type {string}
 */
export const DEFAULT_COMPARISON_JOB = 'PROFESSIONALS';

/**
 * Constant representing the default industry key for comparisons.
 *
 * @type {string}
 */
export const DEFAULT_COMPARISON_INDUSTRY = '51';

/**
 * Constant object that maps EEO gender short values to numeric values for sorting.
 *
 * @type {{ string: number; }}
 */
export const EEOSortOrder = {
  HISP: 1,
  WH: 2,
  BLK: 3,
  ASIAN: 4,
  NHOPI: 5,
  AIAN: 6,
  TOMR: 7,
  MINORITY: 8,
  MALE: 9,
  FEMALE: 10,
};

/**
 * Constant representing the years supported on the homepage.
 *
 * @type {int[]}
 */
export const COMPARISON_YEARS = [2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023];

/**
 * Constant representing the job category keys.
 *
 * @type {string[]}
 */
export const JOB_CATEGORIES = [
  'ALL',
  'SRMANAGERS',
  'MIDMANAGERS',
  'PROFESSIONALS',
  'TECHNICIANS',
  'SALESWORKERS',
  'CLERICALS',
  'CRAFTWORKERS',
  'OPERATIVES',
  'LABORERS',
  'SERVICEWORKERS',
];

/**
 * Constant the maps the sector key strings to an array of the included codes and the sector name.
 *
 * @type {{string: { naics_2_codes: int[], sector_name: string }}}
 */
export const SECTORS = {
  '11': {
    naics_2_codes: [11],
    sector_name: 'Agriculture, Forestry, Fishing and Hunting'
  },
  '21': {
    naics_2_codes: [21],
    sector_name: 'Mining, Quarrying, and Oil and Gas Extraction'
  },
  '22': {
    naics_2_codes: [22],
    sector_name: 'Utilities'
  },
  '23': {
    naics_2_codes: [23],
    sector_name: 'Construction'
  },
  '31-33': {
    naics_2_codes: [31, 32, 33],
    sector_name: 'Manufacturing'
  },
  '42': {
    naics_2_codes: [42],
    sector_name: 'Wholesale Trade'
  },
  '44-45': {
    naics_2_codes: [44, 45],
    sector_name: 'Retail Trade'
  },
  '48-49': {
    naics_2_codes: [48, 49],
    sector_name: 'Transportation and Warehousing'
  },
  '51': {
    naics_2_codes: [51],
    sector_name: 'Information'
  },
  '52': {
    naics_2_codes: [52],
    sector_name: 'Finance and Insurance'
  },
  '53': {
    naics_2_codes: [53],
    sector_name: 'Real Estate and Rental and Leasing'
  },
  '54': {
    naics_2_codes: [54],
    sector_name: 'Professional, Scientific, and Technical Services'
  },
  '55': {
    naics_2_codes: [55],
    sector_name: 'Management of Companies and Enterprises'
  },
  '56': {
    naics_2_codes: [56],
    sector_name: 'Administrative and Support and Waste Management and Remediation Services'
  },
  '61': {
    naics_2_codes: [61],
    sector_name: 'Educational Services'
  },
  '62': {
    naics_2_codes: [62],
    sector_name: 'Health Care and Social Assistance'
  },
  '71': {
    naics_2_codes: [71],
    sector_name: 'Arts, Entertainment, and Recreation'
  },
  '72': {
    naics_2_codes: [72],
    sector_name: 'Accommodation and Food Services'
  },
  '81': {
    naics_2_codes: [81],
    sector_name: 'Other Services (except Public Administration)'
  },
  '92': {
    naics_2_codes: [92],
    sector_name: 'Public Administration'
  },
};

/**
 * Constant array with the combined race and gender in presentation order.
 *
 * @type {string[]}
 */
export const CHART_ORDER = [
  'HISP_MALE',
  'HISP_FEMALE',
  'WH_MALE',
  'BLK_MALE',
  'ASIAN_MALE',
  'NHOPI_MALE',
  'AIAN_MALE',
  'TOMR_MALE',
  'WH_FEMALE',
  'BLK_FEMALE',
  'ASIAN_FEMALE',
  'NHOPI_FEMALE',
  'AIAN_FEMALE',
  'TOMR_FEMALE',
];

/**
 * Constant that maps the combined race and gender key (`RACE_GENDER`) to the full string version.
 *
 * @type {{ string: string }}
 */
export const CODE_MAPPING = {
  HISP_MALE: 'Hispanic Male',
  HISP_FEMALE: 'Hispanic Female',
  WH_MALE: 'White Male',
  BLK_MALE: 'Black/African American Male',
  ASIAN_MALE: 'Asian Male',
  NHOPI_MALE: 'Native Hawaiian/Pacific Islander Male',
  AIAN_MALE: 'American Indian/Alaska Native Male',
  TOMR_MALE: 'Two or More Races Male',
  WH_FEMALE: 'White Female',
  BLK_FEMALE: 'Black/African American Female',
  ASIAN_FEMALE: 'Asian Female',
  NHOPI_FEMALE: 'Native Hawaiian/Pacific Islander Female',
  AIAN_FEMALE: 'American Indian/Alaska Native Female',
  TOMR_FEMALE: 'Two or More Races Female',
}

/**
 * Constant that maps combined race and gender key (`RACE_GENDER`) to chart colors in hex.
 *
 * @type {{ string: string }}
 */
export const CHART_COLORS = {
  HISP_MALE: '#240038',
  HISP_FEMALE: '#165459',
  WH_MALE: '#7D0226',
  BLK_MALE: '#3E3E23',
  NHOPI_MALE: '#193006',
  ASIAN_MALE: '#9b1e95',
  AIAN_MALE: '#391cbb',
  TOMR_MALE: '#8b2929',
  WH_FEMALE: '#A8450C',
  BLK_FEMALE: '#01579B',
  NHOPI_FEMALE: '#082b3d',
  ASIAN_FEMALE: '#350808',
  AIAN_FEMALE: '#502b3f',
  TOMR_FEMALE: '#00824c',
};
