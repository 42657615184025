export async function shareComparison(button, companyName1, companyName2, sectorName) {

  const shareText = `Compare demographics for ${companyName1}, ${companyName2}, and the ${sectorName} industry`;
  const copyText = `${shareText} by visiting ${window.location}`;

  shareBase(button, shareText, copyText);
}

export async function shareCompany(button, companyName, sectorName) {
  const shareText = `See ${companyName} and ${sectorName} industry demographics over time`;
  const copyText = `${shareText} by visiting ${window.location}`;

  shareBase(button, shareText, copyText);
}

export async function shareGeneric(button) {
  const shareText = 'See U.S. industry demographics';
  const copyText = `See U.S. industry demographics by visiting ${window.location}`;

  shareBase(button, shareText, copyText);
}

async function shareBase(button, shareText, copyText) {
  const shareData = {
    title: 'Employees.fyi',
    text: shareText,
    url: window.location,
  };

  try {
    if (navigator.share) {
      await navigator.share(shareData);
    } else {
      navigator.clipboard.writeText(copyText);
      const previousText = button.innerText;
      button.innerText = 'Copied!';
      setTimeout(() => {
        button.innerText = previousText;
      }, 3000);
    }
  } catch (err) {
    console.log(`Error: ${err}`);
  }
}
