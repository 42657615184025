import {
  API_RECORDS,
  API_COMPANIES,
  DEFAULT_COMPARISON_YEAR,
  DEFAULT_COMPARISON_JOB,
  DEFAULT_COMPARISON_INDUSTRY,
  EEOSortOrder,
  COMPARISON_YEARS,
  JOB_CATEGORIES,
  SECTORS,
} from './constants';

import {
  EEOValueMap,
  STATUS_MESSAGES,
  TOTAL_PEOPLE_MESSAGE,
} from './strings';

import { shareComparison } from './sharing.js';

let selectedYear = '';
let selectedJob = '';
let selectedIndustry = '';
let selectedCompany1 = '';
let selectedCompany2 = '';
const selectedCompanyIndustries = [-1, -1];

/**
* Loads the selections based on URL params.
*/
function loadFromURLParams() {
  if ('URLSearchParams' in window) {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('year')) {
      const stringYear = searchParams.get('year');

      const year = parseInt(stringYear, 10);
      if (!Number.isNaN(year) && COMPARISON_YEARS.includes(year)) {
        selectedYear = year;
      } else {
        selectedYear = DEFAULT_COMPARISON_YEAR;
      }
    } else {
      selectedYear = DEFAULT_COMPARISON_YEAR;
    }

    if (searchParams.has('reference')) {
      const industry = searchParams.get('reference');
      if (Object.prototype.hasOwnProperty.call(SECTORS, industry)) {
        selectedIndustry = industry;
      } else {
        selectedIndustry = DEFAULT_COMPARISON_INDUSTRY;
      }
    } else {
      selectedIndustry = DEFAULT_COMPARISON_INDUSTRY;
    }

    if (searchParams.has('job')) {
      const jobCategory = searchParams.get('job');
      if (JOB_CATEGORIES.includes(jobCategory)) {
        selectedJob = jobCategory;
      } else {
        selectedJob = DEFAULT_COMPARISON_JOB;
      }
    } else {
      selectedJob = DEFAULT_COMPARISON_JOB;
    }

    if (searchParams.has('company1')) {
      selectedCompany1 = searchParams.get('company1');
    } else {
      selectedCompany1 = 'Accenture';
    }

    if (searchParams.has('company2')) {
      selectedCompany2 = searchParams.get('company2');
    } else {
      selectedCompany2 = 'Facebook';
    }
  }
}

/**
* Updates the URL params based on the current selection.
* Uses replaceState to avoid breaking back behavior.
*/
function updateURL() {
  if ('URLSearchParams' in window) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('year', `${selectedYear}`);
    searchParams.set('job', `${selectedJob}`);
    searchParams.set('reference', `${selectedIndustry}`);
    searchParams.set('company1', `${selectedCompany1}`);
    searchParams.set('company2', `${selectedCompany2}`);
    const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newRelativePathQuery);
  }
}

/**
* Update the warning by comparing company industry and reference industry.
* @param {Number} column - The index of the column to update.
*/
function updateCompanyMismatchWarning(column) {
  // Add the warning if there is an industry mismatch.
  if (column > 0 && !SECTORS[selectedIndustry].naics_2_codes.includes(selectedCompanyIndustries[column])) {
    updateColumnMessage(column, 'WARNING', true, 'WARNING_INDUSTRY_MISMATCH');
  }

  if (column > 0 && SECTORS[selectedIndustry].naics_2_codes.includes(selectedCompanyIndustries[column])) {
    updateColumnMessage(column, 'WARNING', false, '');
  }
}

/**
* Update the message on a given data column.
* TODO: Make enums in actual enums.
* @param {Number} column - The index of the column to update.
* @param {String} messageTypeEnum - The enum of the message type.
* @param {Boolean} shouldShow - true to show the message, false to hide it.
* @param {String} messageEnum - The enum of the message to show.
*/
function updateColumnMessage(column, messageTypeEnum, shouldShow, messageEnum) {
  // Check for invalid column param.
  if (column < 0 || column > 2) {
    return;
  }

  // Holds the status or warning element.
  let messageEl;

  if (messageTypeEnum === 'WARNING') {
    messageEl = document.querySelectorAll('.column-warning')[column];
  } else if (messageTypeEnum === 'STATUS') {
    messageEl = document.querySelectorAll('.data-column-status')[column];
  } else {
    return;
  }

  // If this is a call to hide the status message, hide it and return.
  if (shouldShow === false) {
    messageEl.style.display = 'none';
    return;
  }

  if (Object.prototype.hasOwnProperty.call(STATUS_MESSAGES, messageEnum)) {
    messageEl.style.display = 'block';
    messageEl.innerText = STATUS_MESSAGES[messageEnum];
  }
}

function updateColumn(column, newData) {
  // Update the URL params based on the selection.
  updateURL();

  // If we received no data, return quickly. This is unlikely.
  if (!newData || newData.length === 0) {
    updateColumnMessage(column, 'WARNING', true, 'WARNING_NO_DATA');
    updateColumnMessage(column, 'STATUS', true, 'STATUS_NO_DATA');
    return;
  }

  // Holds the total value of all rows.
  let totalCount = 0;

  // Grab total count for now. TODO: optimize into a single loop!
  newData.forEach((item) => {
    totalCount += item.Value;
  });

  const countEl = document.getElementsByClassName('column-count')[column];
  countEl.innerText = `${TOTAL_PEOPLE_MESSAGE} ${totalCount.toLocaleString()}`;

  if (column > 0) {
    const industryInfo = document.getElementsByClassName('company-industry')[column - 1];
    industryInfo.innerText = newData[0].NAICSLabel;
    selectedCompanyIndustries[column] = newData[0].NAICSCode;
    // Add the warning if there is an industry mismatch.
    updateCompanyMismatchWarning(column);
  } else {
    // This is a reference column change and we should update the warnings for companies.
    updateCompanyMismatchWarning(1);
    updateCompanyMismatchWarning(2);
  }

  // If we received data but all rows are zero, we should say so and return.
  if (totalCount === 0) {
    updateColumnMessage(column, 'STATUS', true, 'STATUS_NO_MEMBERS');
    return;
  }

  updateColumnMessage(column, 'STATUS', false, '');

  // Create template nodes to clone. TODO: consider removing.
  const dataRowTemplate = document.createElement('div');
  dataRowTemplate.classList.add('data-row');
  const linkTemplate = document.createElement('a');
  linkTemplate.setAttribute('target', '_blank');
  linkTemplate.setAttribute('rel', 'noopener');

  // Grab all of the columns, then select the right ones.
  const columnsList = document.getElementsByClassName('data-column-rows');
  const skippedList = document.getElementsByClassName('data-column-skipped');
  const columnEl = columnsList[column];
  const skippedEl = skippedList[column];

  // Sort the data for consistent comparisons.
  newData.sort((x, y) => {
    if (EEOSortOrder[x.RaceEthnicId] < EEOSortOrder[y.RaceEthnicId]) {
      return -1;
    }

    if (EEOSortOrder[x.RaceEthnicId] > EEOSortOrder[y.RaceEthnicId]) {
      return 1;
    }

    if (EEOSortOrder[x.RaceEthnicId] === EEOSortOrder[y.RaceEthnicId]) {
      if (EEOSortOrder[x.Gender] > EEOSortOrder[y.Gender]) {
        return 1;
      }
      if (EEOSortOrder[x.Gender] < EEOSortOrder[y.Gender]) {
        return -1;
      }
    }
    return 0;
  });

  let otherTotal = 0;

  newData.forEach((item) => {
    if (item.Gender !== 'ALL' && item.RaceEthnicId !== 'MINORITY') {
      let percentage = ((item.Value / totalCount) * 100);
      if (Number.isNaN(percentage)) {
        percentage = 0;
      }

      const content = `${percentage.toFixed(2)}% - ${EEOValueMap[item.RaceEthnicId]} ${EEOValueMap[item.Gender]}`;

      if (percentage >= 5) {
        const newRow = dataRowTemplate.cloneNode(true);
        const newContent = document.createTextNode(content);
        newRow.appendChild(newContent);
        newRow.style.height = `calc(${percentage}%)`;
        newRow.classList.add(`${item.RaceEthnicId.toLowerCase()}-${item.Gender.toLowerCase()}-data-row`);
        columnEl.appendChild(newRow);
      } else {
        otherTotal += percentage;
        const skippedItem = document.createElement('li');
        const skippedItemLabel = document.createTextNode(content);
        skippedItem.appendChild(skippedItemLabel);
        skippedEl.appendChild(skippedItem);
      }
    }
  });

  if (otherTotal > 0) {
    const content = `${otherTotal.toFixed(2)}% - other groups, check below`;
    const newRow = dataRowTemplate.cloneNode(true);
    const newContent = document.createTextNode(content);
    newRow.appendChild(newContent);
    newRow.classList.add('other-groups-data-row');
    newRow.style.height = `calc(${otherTotal}%)`;
    columnEl.appendChild(newRow);
  }

  const sourcesDiv = document.getElementsByClassName('data-column-sources')[column];
  const sourceLink = linkTemplate.cloneNode(true);
  const archiveLink = linkTemplate.cloneNode(true);

  let linkLabelPrefix;
  if (column === 0) {
    linkLabelPrefix = 'Reference ';
  } else if (column === 1) {
    linkLabelPrefix = 'Company 1 ';
  } else {
    linkLabelPrefix = 'Company 2 ';
  }
  sourceLink.setAttribute('href', `${newData[0].SourceURL}`);
  sourceLink.innerText = `${linkLabelPrefix}Source`;
  archiveLink.setAttribute('href', `${newData[0].ArchiveURL}`);
  archiveLink.innerText = `${linkLabelPrefix}Archive`;
  sourcesDiv.appendChild(sourceLink);
  sourcesDiv.appendChild(archiveLink);
}

function fetchFromAPI(column, year, dataset, role, industry) {
  const url = new URL(API_RECORDS);

  const params = {
    year,
    dataset,
    role,
    industry,
  };

  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

  fetch(url)
    .then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          updateColumn(column, data);
        });
      } else {
        Promise.reject(new Error('Failed to load data from server'));
      }
    })
    .catch((error) => console.error(error));
}

/**
* Clear the data from one column on the page.
* @param {number} column - The zero-indexed data column to clear.
*/
function clearColumn(column) {
  const columnsList = document.getElementsByClassName('data-column-rows');
  const skippedList = document.getElementsByClassName('data-column-skipped');
  const sourcesList = document.getElementsByClassName('data-column-sources');
  const columnEl = columnsList[column];
  const skippedEl = skippedList[column];
  const sourcesDiv = sourcesList[column];

  // Clear the current children.
  while (columnEl.firstChild) { columnEl.removeChild(columnEl.firstChild); }
  while (skippedEl.firstChild) { skippedEl.removeChild(skippedEl.firstChild); }
  while (sourcesDiv.firstChild) { sourcesDiv.removeChild(sourcesDiv.firstChild); }

  // Update warnings and statuses.
  updateColumnMessage(column, 'WARNING', false, '');
  updateColumnMessage(column, 'STATUS', true, 'STATUS_LOADING');

  // Operations specific to company data columns.
  if (column > 0) {
    const industryInfo = document.getElementsByClassName('company-industry')[column - 1];
    industryInfo.innerText = '';
  }
}

function startColumnUpdate(column, year, dataset, job, industry) {
  clearColumn(column);
  if (column === 0) {
    // This is a reference column update, where no dataset param is present.
    let refDataset;
    if (year < 2019) {
      refDataset = `YEAR${year.toString().substring(2)}_NAC2`;
    } else {
      refDataset = `EEOC_PUF_${year.toString()}`;
    }
    fetchFromAPI(column, year, refDataset, job, industry);
  } else if (dataset !== '') {
    fetchFromAPI(column, year, dataset, job, industry);
  } else {
    updateColumnMessage(column, 'STATUS', true, 'STATUS_NO_DATA');
    const countEl = document.getElementsByClassName('column-count')[column];
    countEl.innerText = `${TOTAL_PEOPLE_MESSAGE} 0`;
  }
}

/**
* Performs a basic check to make sure we have the core values for an API call.
* TODO: Consider eliminating or changing this.
*/
function checkBeforeColumnUpdate() {
  if (selectedYear === -1 || selectedJob === '' || selectedIndustry === -1) {
    return false;
  }
  return true;
}

function fetchCompaniesForYear(year) {
  const url = new URL(API_COMPANIES);

  const params = { year };

  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

  fetch(url)
    .then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          updateCompanyOptions(data);
        });
      } else {
        Promise.reject(new Error('Failed to load data from server'));
      }
    })
    .catch((error) => console.error(error));
}

/**
* Update the options available in the company <select> elements.
* TODO: Make type an int instead of a string for performance.
* @param {Event} event - The Event coming from the <select> element.
*/
function selectChangeHandler(event) {
  const type = event.target.id;
  switch (type) {
    case 'year': {
      const year = parseInt(event.target.value, 10);
      selectedYear = year;

      if (checkBeforeColumnUpdate()) {
        startColumnUpdate(0, selectedYear, '', selectedJob, selectedIndustry);
      }
      fetchCompaniesForYear(selectedYear);
      break;
    }
    case 'job': {
      const job = event.target.value;
      selectedJob = job;
      if (checkBeforeColumnUpdate()) {
        startColumnUpdate(0, selectedYear, '', selectedJob, selectedIndustry);
        startColumnUpdate(1, selectedYear, selectedCompany1, selectedJob, 0);
        startColumnUpdate(2, selectedYear, selectedCompany2, selectedJob, 0);
      }
      break;
    }
    case 'industry': {
      selectedIndustry = event.target.value;
      if (checkBeforeColumnUpdate()) {
        startColumnUpdate(0, selectedYear, '', selectedJob, selectedIndustry);
      }
      break;
    }
    case 'company-1': {
      const company1 = event.target.value;
      selectedCompany1 = company1;
      if (checkBeforeColumnUpdate()) {
        startColumnUpdate(1, selectedYear, selectedCompany1, selectedJob, 0);
      }
      break;
    }
    case 'company-2': {
      const company2 = event.target.value;
      selectedCompany2 = company2;
      if (checkBeforeColumnUpdate()) {
        startColumnUpdate(2, selectedYear, selectedCompany2, selectedJob, 0);
      }
      break;
    }
    default:
      // TODO
      break;
  }
}

function removeCompanySelectorHandlers() {
  const companySelectors = document.querySelectorAll('.company-selector');
  companySelectors[0].removeEventListener('change', selectChangeHandler);
  companySelectors[1].removeEventListener('change', selectChangeHandler);
}

function addCompanySelectorHandlers() {
  const companySelectors = document.querySelectorAll('.company-selector');
  companySelectors[0].addEventListener('change', selectChangeHandler);
  companySelectors[1].addEventListener('change', selectChangeHandler);
}

/**
* Update the options available in the company <select> elements.
* @param {string[]} companies - Companies to add to company <select> elements.
*/
function updateCompanyOptions(companies) {
  removeCompanySelectorHandlers();
  const companySelectors = document.querySelectorAll('.company-selector');

  let foundCompany1 = false;
  let foundCompany2 = false;

  // Empty the current select options.
  companySelectors[0].length = 0;
  companySelectors[1].length = 0;

  // Set values for the first selector.
  if (companies.length >= 1) {
    companies.forEach((company) => {
      const option = document.createElement('option');
      option.value = company;
      option.text = company;
      if (company === selectedCompany1) {
        foundCompany1 = true;
        option.selected = true;
      }
      companySelectors[0].add(option);
    });

    // Set values for the second selector.
    companies.forEach((company) => {
      const option = document.createElement('option');
      option.value = company;
      option.text = company;
      if (company === selectedCompany2) {
        foundCompany2 = true;
        option.selected = true;
      }
      companySelectors[1].add(option);
    });

    // If the prior company wasn't availabe in the new list, select the first one.
    if (!foundCompany1) {
      companySelectors[0].options[0].selected = true;
      selectedCompany1 = companySelectors[0].options[0].value;
    }

    // If the prior company wasn't availabe in the new list, select the first or second one.
    if (!foundCompany2) {
      if (companies.length >= 2) {
        companySelectors[1].options[1].selected = true;
        selectedCompany2 = companySelectors[1].options[1].value;
      } else {
        companySelectors[1].options[0].selected = true;
        selectedCompany2 = companySelectors[1].options[0].value;
      }
    }
  }

  // Re-add event listeners, which will (correctly) respond to the changes below.
  addCompanySelectorHandlers();
  companySelectors[0].dispatchEvent(new Event('change'));
  companySelectors[1].dispatchEvent(new Event('change'));
}

/**
* Add event handlers to the <select> elements on the page.
*/
function addEvents() {
  const yearSelector = document.getElementById('year');
  const jobSelector = document.getElementById('job');
  const industrySelector = document.getElementById('industry');
  const company1Selector = document.getElementById('company-1');
  const company2Selector = document.getElementById('company-2');

  yearSelector.addEventListener('change', selectChangeHandler);
  jobSelector.addEventListener('change', selectChangeHandler);
  industrySelector.addEventListener('change', selectChangeHandler);
  company1Selector.addEventListener('change', selectChangeHandler);
  company2Selector.addEventListener('change', selectChangeHandler);

  const shareButtonNav = document.getElementById('share-button-nav');
  shareButtonNav.addEventListener('click', () => {
    shareComparison(shareButtonNav, selectedCompany1, selectedCompany2, SECTORS[selectedIndustry].sector_name);
  });
}

function updateSelectorFromValue(selectorId, value) {
  document.getElementById(selectorId).value = value;
}

/**
* Init for everything to start running.
*/
function init() {
  loadFromURLParams();
  updateSelectorFromValue('year', selectedYear);
  updateSelectorFromValue('job', selectedJob);
  updateSelectorFromValue('industry', selectedIndustry);
  addEvents();
  if (checkBeforeColumnUpdate()) {
    startColumnUpdate(0, selectedYear, '', selectedJob, selectedIndustry);
    fetchCompaniesForYear(selectedYear);
  }
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}
